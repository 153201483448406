import { Avatar, Backdrop, Box, Button, CircularProgress, Divider, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, styled, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, tooltipClasses, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import HeaderSection from '../../Components/HeaderSection'
import { BookButton, SecondaryText } from '../../Styles/Form/Field';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePaymentInitiateMutation } from '../../Services/ExportPaymentApi';
import { useImportPaymentInitiateMutation } from '../../Services/ImportPaymentApi';
import { useSnackbar } from 'notistack';
import PaymentIcon from '@mui/icons-material/Payment';
import { useSelector } from 'react-redux';
import { useLazyFetchWalletDetailQuery } from '../../Services/AccountApi';
import { AmountSummaryStyle } from '../../Styles/Common/style';
import InfoIcon from '../../Assets/icons/info-icon.svg';
import MoneyIcon from '../../Assets/images/money.webp';
import { formTitle } from '../../Styles/Import/formSection';
import { Helmet } from 'react-helmet-async';
import WalletIcon from '@mui/icons-material/Wallet';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Billing = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const { mode, amount_sum, sgst, total_amount_sum, cgst } = location.state || {};
  const [walletDetailApi, { refetch: walletDetailRefetch }] = useLazyFetchWalletDetailQuery();
  const [walletDetail, setWalletDetail] = useState({})
  const { enqueueSnackbar } = useSnackbar();
  const [selectWallet, setSelectWallet] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const user = useSelector(state => state?.user?.user);
  const [paymentInitiate, { isLoading: paymentInitiateLoading }] = usePaymentInitiateMutation();
  const [importPaymentInitiate, { isLoading: importPaymentInitiateLoading }] = useImportPaymentInitiateMutation();
  const [paymentMethod, setPaymentMethod] = useState('online');

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        handlePayment()
      }
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    if (!amount_sum) {
      enqueueSnackbar('Invalid amount passed !', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      navigate('/billing/invoices')
    }
  }, [amount_sum, navigate, enqueueSnackbar])

  useEffect(() => {
    const getWalletDetail = async () => {
      const { data, error } = await walletDetailApi({}, selectWallet)
      if (data) {
        setWalletDetail(data)
      }
      if (error) {
        setWalletDetail({})
      }
    }
    getWalletDetail()
  }, [walletDetailApi])

  const handlePayment = async () => {
    try {
      if (selectWallet && walletDetail?.data?.balance < total_amount_sum){
        enqueueSnackbar('Insufficent funds in wallet!!. choose another method', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        setPaymentLoading(false);
      }
      else{
          let paymentInitiateFunction = mode === "export" ? paymentInitiate : importPaymentInitiate;
          const { data, error } = await paymentInitiateFunction({
            use_wallet: selectWallet,
            payment_type: (paymentMethod === 'cash') ? 'cash' : null
          });
          if (error) {
            if (error?.data?.message === "Invalid amount passed") {
              enqueueSnackbar('Invalid amount passed !', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
              });
              setPaymentLoading(false);
            } else {
              setTimeout(() => {
                setPaymentLoading(false);
                navigate('/billing/invoices', { replace: true });
              }, 5000);
            }
            return;
          }
          if (selectWallet && data) {
            setPaymentLoading(true);
            enqueueSnackbar('Payment Successful', {
              variant: 'success',
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });

            if (data.successurl) {
              const newWindow = window.open(data.successurl);
              setTimeout(() => {
                if (newWindow && !newWindow.closed) {
                  newWindow.close();
                }
                navigate('/billing/invoices', { replace: true });
              }, 2700);
            } else {
              navigate('/billing/invoices', { replace: true });
            }
            return;
          }
          if (data) {
            if (data?.paymentUrl) {
              window.open(data.paymentUrl);
              setPaymentLoading(true);
            }

            if (data?.payment_type === 'cash') {
              setPaymentLoading(true);
            }

            if (data?.message === "Payment Successfull") {
              enqueueSnackbar('Payment Successful', {
                variant: 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
              });
              navigate('/billing/invoices', { replace: true });
            }

            if (data?.successurl && !selectWallet) {
              window.location.href = data.successurl;
            }
          }
      }

    } catch (err) {
      console.error('Payment error:', err);
      setPaymentLoading(false);
      enqueueSnackbar('Payment failed', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      navigate('/billing/invoices', { replace: true });
    }
  };
  return (
    <Box >
      <Helmet>
        <title>Billing | Gati se Pragati</title>
      </Helmet>
      <HeaderSection title="PAYMENT DETAILS" />
      <Box sx={{ width: '90%', margin: '0 auto', minHeight: "400px" }}>
        <Grid container spacing={5} marginTop={'0px'}>
          <Grid item xs={12} md={7}>
            <Box>
              <Typography sx={{ ...formTitle, textAlign: "left", mb: "1rem" }}>Payment Method</Typography>

              <Box sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "60%",
                  lg: "60%",
                  xl: "60%",
                },
                background: '#F3F5F8 0% 0% no-repeat padding-box',
                borderRadius: '10px'
              }}>
                <Box>
                  <List sx={{
                    width: "94%",
                    padding: "1rem 0 1rem 0",
                    margin: "auto"
                  }}>
                    <ListItemButton
                      sx={{
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                      }}
                      // selected={selectWallet}
                      selected={paymentMethod === 'Wallet'}
                      onClick={(event) => {
                        setSelectWallet(true);
                        setPaymentMethod('Wallet');
                      }
                      }
                    >
                      <ListItemIcon>
                        <WalletIcon />
                      </ListItemIcon>
                      <ListItemText primary="Use Wallet" />
                    </ListItemButton>
                    <ListItemButton
                      selected={paymentMethod === 'online'}
                      onClick={() => {setPaymentMethod('online');
                        setSelectWallet(false);
                      }}
                      sx={{
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                      }}
                    >
                      <ListItemIcon>
                        <PaymentIcon />
                      </ListItemIcon>
                      <ListItemText primary="Online" />
                    </ListItemButton>
                    <ListItemButton
                      selected={paymentMethod === 'cash'}
                      onClick={() => {setPaymentMethod('cash');
                        setSelectWallet(false);
                      }}
                      sx={{
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                      }}
                    >
                      <ListItemIcon>
                        <img src={MoneyIcon} alt="" width={"20"} height={"16"} />
                      </ListItemIcon>
                      <ListItemText primary="CASH" />
                    </ListItemButton>
                  </List>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box>
              <Typography sx={{ ...formTitle, textAlign: "left" }}>Payment Summary</Typography>
              <Box sx={{
                width: '100%',
                height: '100%',
                margin: "1rem 0",
                borderRadius: "8px",
                padding: "1rem",
                background: '#F3F5F8 0% 0% no-repeat padding-box'
              }}>
                <TableContainer sx={{
                  background: "#fff",
                  borderRadius: "10px",
                  marginBottom: "1rem"
                }} >
                  <Table
                    aria-label="simple table"
                  >
                    <TableBody>

                      <TableRow>
                        <TableCell colSpan={4} sx={{ fontSize: '16px', border: 'none' }} align="center">
                          <List>
                            <ListItem alignItems="flex-start">
                              <ListItemAvatar sx={{ minWidth: "40px", margin: "auto" }} >
                                <Avatar sx={{
                                  width: {
                                    xs: "30px",
                                    sm: "30px",
                                    md: "30px",
                                    lg: "30px",
                                    xl: "30px",
                                  },
                                  height: {
                                    xs: "30px",
                                    sm: "30px",
                                    md: "30px",
                                    lg: "30px",
                                    xl: "30px",
                                  }
                                }} alt="Remy Sharp" />
                              </ListItemAvatar>
                              <ListItemText
                                primary={user?.name}
                                primaryTypographyProps={{
                                  sx: {
                                    fontFamily: 'Poppins, sans-serif',
                                    fontSize: {
                                      xs: "14px",
                                      sm: "20px",
                                      md: "20px",
                                      lg: "24px",
                                      xl: "30px",
                                    },
                                    lineHeight: '32px',
                                    fontWeight: 500, // 'medium' corresponds to 500
                                    letterSpacing: '0px',
                                    color: '#000000',
                                    textTransform: 'capitalize',
                                    opacity: 1,
                                  }
                                }}
                              />
                            </ListItem>
                          </List>
                          <Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                              <Typography textAlign="end" sx={AmountSummaryStyle}>
                                Order Amount
                              </Typography>
                              <Typography sx={AmountSummaryStyle}>
                                {amount_sum?.toFixed(2)} ₹
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                              <Typography textAlign="end" sx={SecondaryText}>
                                <LightTooltip title="9% SGST and 9% CGST are applied as per the applicable tax rates for goods and services under the GST regulations." arrow>
                                  <img src={InfoIcon} alt="" width="15px" height="15px" />{' '}
                                </LightTooltip>
                                SGST({sgst?.percent})
                              </Typography>
                              <Typography sx={SecondaryText}>
                                {sgst?.amount?.toFixed(2)} ₹
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                              <Typography textAlign="end" sx={SecondaryText}>
                                &emsp;CGST({cgst?.percent})
                              </Typography>
                              <Typography sx={SecondaryText}>
                                {cgst?.amount?.toFixed(2)} ₹
                              </Typography>
                            </Box>
                            <Divider />
                            <Box>
                              {selectWallet && (
                                <>
                                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography textAlign="end" sx={AmountSummaryStyle}>Wallet (Availble Balance) - </Typography>
                                    <Typography sx={AmountSummaryStyle}>
                                      {walletDetail?.data?.balance?.toFixed(2)} ₹
                                    </Typography>
                                  </Box>
                                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography textAlign="end" sx={AmountSummaryStyle}>Amount Deducted -</Typography>
                                    <Typography sx={AmountSummaryStyle}>
                                      {(walletDetail?.data?.balance > total_amount_sum?.toFixed(2)) ?
                                        (total_amount_sum?.toFixed(2)) :
                                        (walletDetail?.data?.balance?.toFixed(2))
                                      } ₹
                                    </Typography>
                                  </Box>
                                </>
                              )}

                              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography textAlign="end" sx={AmountSummaryStyle} >
                                  Total Amount Payable -
                                </Typography>
                                <Typography sx={AmountSummaryStyle}>
                                  {(selectWallet && walletDetail?.data?.balance > 0) ? (
                                    (walletDetail?.data?.balance > total_amount_sum) ?
                                    "0.00" :
                                    (Number(total_amount_sum) - Number(walletDetail?.data?.balance)).toFixed(2)
                                    ) : (
                                      total_amount_sum?.toFixed(2)
                                    )
                                  } ₹
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button sx={{ ...BookButton }} onClick={handlePayment}>
                  {total_amount_sum === 0 ? "Generate the invoice for this order" :
                    "Submit Order"}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={paymentInitiateLoading || importPaymentInitiateLoading || paymentLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default Billing