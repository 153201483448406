import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { BookButton, FormInputField, FormLabelStyle } from '../Styles/Form/Field';
import CustomUploadField from './CustomUploadField';
import { fetchFormSchema, NewTranporterFormSchema } from '../Utils/ValidationSchemas';
import { MuiTelInput } from 'mui-tel-input';
import { useFetchThirthPartyTransporterMutation } from '../Services/ExportApi';
import GlobalLoader from './GlobalLoader';
const LabelStyle = {
    marginBottom: "0.3rem",
    fontStyle: "normal",
    fontWeight: "normal", // 'normal' corresponds to a font weight of 400
    fontSize: {
        xs: "14px",
        sm: "14px",
        md: "14px",
        lg: "14px",
        xl: "16px",
    },
    lineHeight: "25px",
    fontFamily: "Roboto",
    letterSpacing: "0px",
};
const SelectFieldStyle = {
    '& .MuiSelect-select': {
        backgroundColor: '#F3F5F8',
        color: '#000000',
        borderRadius: '8px',
        fontSize: {
            xs: '12px',
            sm: '14px',
            md: '14px',
            lg: '16px',
            xl: '18px',
        },
    },
    '& .muiSelect-outlined': {
        borderRadius: {
            xs: '2px',
            sm: '2px',
            md: '4px',
            lg: '8px',
            xl: '8px',
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderRadius: {
            xs: '2px',
            sm: '2px',
            md: '4px',
            lg: '8px',
            xl: '8px',
        }
    },
}

const AddNewTransporterModal = ({ open, closeModal, defaultValue, apiLoading, handleSaveBillGenrationDetails }) => {
    const [fetchDetailsApi, { isLoading }] = useFetchThirthPartyTransporterMutation()
    const [value, setValue] = useState(defaultValue ? 'other' : 'self');
    const [fetchData, setFetchData] = useState(Boolean(defaultValue));
    const handleChange = (event) => {
        formik.resetForm()
        setValue(event.target.value);
    };

    const formik = useFormik({
        initialValues: {
            third_party_transporter_id: defaultValue?.id || "",
            first_name: defaultValue?.first_name || "",
            last_name: defaultValue?.last_name || "",
            email: defaultValue?.email || "",
            phone_number: defaultValue?.phone_number || "+91",
            gstin_number: defaultValue?.gstin_number || "",
            pan_number: defaultValue?.pan_number || "",
            gstin_doc: defaultValue?.gstin_doc || null,
            pan_doc: defaultValue?.pan_doc || null,
        },
        validationSchema: !fetchData ? fetchFormSchema : NewTranporterFormSchema,
        onSubmit: (values, { resetForm }) => {
            let formData = structuredClone(values)
            if (defaultValue) {
                formData['id'] = defaultValue?.id
            }
            if (!(formData['gstin_doc'] instanceof File)) {
                delete formData['gstin_doc']
            }
            if (!(formData['pan_doc'] instanceof File)) {
                delete formData['pan_doc']
            }
            formData['third_party_transporter'] = value !== "self"
            handleSaveBillGenrationDetails(formData)
        },
    });

    const handleDocumentNumber = (event) => {
        const { name, value } = event.target
        formik.setFieldValue(name, value?.toUpperCase())
    }

    const handleFetchDetails = async () => {
        let params = {
            first_name: formik.values.first_name,
            last_name: formik.values.last_name,
            email: formik.values.email,
            gstin_number: formik.values.gstin_number,
        }
        const { data, error } = await fetchDetailsApi(params)
        if (data) {
            let values = {
                third_party_transporter_id: data?.data?.id,
                first_name: data?.data?.first_name,
                last_name: data?.data?.last_name,
                email: data?.data?.email,
                phone_number: data?.data?.phone_number,
                gstin_number: data?.data?.gstin_number,
                pan_number: data?.data?.pan_number,
                gstin_doc: data?.data?.gstin_doc,
                pan_doc: data?.data?.pan_doc,
            }
            formik.setValues(values)
        }
        setFetchData(true)

    }
    return (
        <Dialog
            open={open}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"sm"}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '16px'
                }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
                {"Invoice Generation"}
            </DialogTitle>
            <DialogContent sx={{
                margin: '1rem', padding: {
                    xs: "20px 0px",
                    sm: "20px 0px",
                    md: "20px 24px",
                    lg: "20px 24px",
                    xl: "20px 24px",
                }
            }}>

                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2} sx={{ mt: '0px' }}>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                                <Typography sx={{ ...FormLabelStyle }}>Bill To <span style={{ color: "red" }}>*</span></Typography>
                                <Select
                                    fullWidth
                                    sx={SelectFieldStyle}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={value}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"self"}>Self</MenuItem>
                                    <MenuItem value={"other"}>Other</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {value === "other" &&
                            <>
                                <Grid item xs={12} md={12}>
                                    <Typography sx={{ ...FormLabelStyle }}>First Name <span style={{ color: "red" }}>*</span></Typography>
                                    <TextField
                                        fullWidth
                                        // type="number"
                                        sx={{
                                            ...FormInputField,
                                            "& .Mui-focused": {
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: "none"
                                                },
                                            }
                                        }}
                                        name={'first_name'}
                                        placeholder={'Enter Name'}
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.first_name && Boolean(formik.errors.first_name)
                                        }
                                    />
                                    <FormHelperText sx={{ marginLeft: '14px' }} error>
                                        {formik.touched.first_name ? formik.errors.first_name : ''}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography sx={{ ...FormLabelStyle }}>Last Name <span style={{ color: "red" }}>*</span></Typography>
                                    <TextField
                                        fullWidth
                                        // type="number"
                                        sx={{
                                            ...FormInputField,
                                            "& .Mui-focused": {
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: "none"
                                                },
                                            }
                                        }}
                                        name={'last_name'}
                                        placeholder={'Enter Name'}
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.last_name && Boolean(formik.errors.last_name)
                                        }
                                    />
                                    <FormHelperText sx={{ marginLeft: '14px' }} error>
                                        {formik.touched.last_name ? formik.errors.last_name : ''}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography sx={{ ...FormLabelStyle }}>Email <span style={{ color: "red" }}>*</span></Typography>
                                    <TextField
                                        fullWidth
                                        // type="number"
                                        sx={{
                                            ...FormInputField,
                                            "& .Mui-focused": {
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: "none"
                                                },
                                            }
                                        }}
                                        name={'email'}
                                        placeholder={'Enter Email'}
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.email && Boolean(formik.errors.email)
                                        }
                                    />
                                    <FormHelperText sx={{ marginLeft: '14px' }} error>
                                        {formik.touched.email ? formik.errors.email : ''}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography sx={{ ...FormLabelStyle }}>GSTIN No. <span style={{ color: "red" }}>*</span></Typography>
                                    <TextField
                                        fullWidth
                                        // type="number"
                                        sx={{
                                            ...FormInputField,
                                            "& .Mui-focused": {
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: "none"
                                                },
                                            }
                                        }}
                                        name={'gstin_number'}
                                        placeholder={'Enter GSTIN Number'}
                                        value={formik.values.gstin_number}
                                        onChange={handleDocumentNumber}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.gstin_number && Boolean(formik.errors.gstin_number)
                                        }
                                    />
                                    <FormHelperText sx={{ marginLeft: '14px' }} error>
                                        {formik.touched.gstin_number ? formik.errors.gstin_number : ''}
                                    </FormHelperText>
                                </Grid>
                                {!fetchData && !defaultValue ?
                                    <Button sx={{ ...BookButton, marginBottom: "0px !important" }} disabled={false} variant="contained" type="button" onClick={() => handleFetchDetails()}>
                                        Fetch Details
                                    </Button> :
                                    <>
                                        <Grid item xs={12} md={12}>
                                            <Typography sx={{ ...LabelStyle }}>Phone Number</Typography>
                                            <MuiTelInput
                                                fullWidth
                                                sx={{
                                                    ...FormInputField,
                                                }}
                                                inputProps={{ maxLength: 16 }}
                                                id="phone_number"
                                                name={'phone_number'}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phone_number}
                                                onChange={(value) => { formik.setFieldValue("phone_number", value) }}
                                                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                                helperText={formik.touched.phone_number ? formik.errors.phone_number : ''}
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={12} md={12}>
                                            <Typography sx={{ ...FormLabelStyle }}>PAN No. <span style={{ color: "red" }}>*</span></Typography>
                                            <TextField
                                                fullWidth
                                                // type="number"
                                                sx={{
                                                    ...FormInputField,
                                                    "& .Mui-focused": {
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            border: "none"
                                                        },
                                                    }
                                                }}
                                                name={'pan_number'}
                                                placeholder={'Enter PAN Number'}
                                                value={formik.values.pan_number}
                                                onChange={handleDocumentNumber}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    formik.touched.pan_number && Boolean(formik.errors.pan_number)
                                                }
                                            />
                                            <FormHelperText sx={{ marginLeft: '14px' }} error>
                                                {formik.touched.pan_number ? formik.errors.pan_number : ''}
                                            </FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <CustomUploadField
                                                name="pan_doc"
                                                required={true}
                                                label="Upload PAN Document"
                                                placeholder="Attach PAN Document"
                                                onChange={(file) => {
                                                    formik.setFieldTouched('pan_doc', true)
                                                    formik.setFieldValue('pan_doc', file)
                                                }
                                                }
                                                error={
                                                    formik.touched.pan_doc && Boolean(formik.errors.pan_doc)
                                                }
                                                helperText={
                                                    formik.touched.pan_doc ? formik.errors.pan_doc : ''
                                                }
                                                value={formik.values.pan_doc}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <CustomUploadField
                                                name="gstin_doc"
                                                required={true}
                                                label="Upload GSTIN Document"
                                                placeholder="Attach GSTIN Document"
                                                onChange={(file) => {
                                                    formik.setFieldTouched('gstin_doc', true)
                                                    formik.setFieldValue('gstin_doc', file)
                                                }
                                                }
                                                error={
                                                    formik.touched.gstin_doc && Boolean(formik.errors.gstin_doc)
                                                }
                                                helperText={
                                                    formik.touched.gstin_doc ? formik.errors.gstin_doc : ''
                                                }
                                                value={formik.values.gstin_doc}
                                            />
                                        </Grid>
                                    </>}
                            </>
                        }
                        <Grid item xs={12} md={12}>
                            {value !== "other" ?
                                <Button sx={{ ...BookButton, marginBottom: "0px !important" }} disabled={false} variant="contained" type="button" onClick={() => handleSaveBillGenrationDetails({ third_party_transporter: false })}>
                                    Payment
                                </Button>
                                :
                                fetchData &&
                                <Button sx={{ ...BookButton, marginBottom: "0px !important" }} disabled={value === "other" && !defaultValue ? !formik.dirty : false} variant="contained" type="submit">
                                    Payment
                                </Button>}
                        </Grid>
                    </Grid>
                </form>
                <GlobalLoader open={Boolean(apiLoading)} />
            </DialogContent>
        </Dialog>
    )
}

export default AddNewTransporterModal