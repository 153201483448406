import * as yup from "yup";

const charDict = {
  A: 10,
  B: 12,
  C: 13,
  D: 14,
  E: 15,
  F: 16,
  G: 17,
  H: 18,
  I: 19,
  J: 20,
  K: 21,
  L: 23,
  M: 24,
  N: 25,
  O: 26,
  P: 27,
  Q: 28,
  R: 29,
  S: 30,
  T: 31,
  U: 32,
  V: 34,
  W: 35,
  X: 36,
  Y: 37,
  Z: 38,
};
const panCardRegex = /^[A-Z]{5}\d{4}[A-Z]$/;
const gstinRegex =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/;
function getCustomCharIndex(char) {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowerChar = char.toUpperCase();
  if (alphabet.includes(lowerChar)) {
    return charDict[lowerChar];
  }

  // If the character is a digit, return its numeric value
  if (!isNaN(char)) {
    return parseInt(char, 10);
  }

  // Return null for any non-alphabet, non-digit characters
  return null;
}

const calculateCheckDigit = (value) => {
  const weights = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512]; // Powers of 2 for each position
  let sum = 0;
  for (let i = 0; i < value?.length - 1; i++) {
    console.log(value[i], getCustomCharIndex(value[i]));
    sum += getCustomCharIndex(value[i]) * weights[i];
  }

  // Step b: Divide by 11
  const division = sum / 11;

  // Step c: Erase decimal digits
  const truncated = Math.floor(division);

  // Step d: Multiply by 11
  const multiply = truncated * 11;

  // Step e: Calculate check digit
  const checkDigit = sum - multiply;
  return checkDigit % 10;
};

// eslint-disable-next-line no-useless-escape
// const phoneRules = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
const alphanumericRegex = /^[a-zA-Z0-9]*$/;
export const exportFormSchema = yup.object().shape({
  yard: yup.string().required("Required"),
  shipping_line: yup.string().required("Required"),
  schedule_date: yup.string().required("Required"),
  bn_number: yup
    .string()
    .required("Required")
    .matches(alphanumericRegex, "Booking Number cannot have special character"),
  validity: yup.string().required("Required"),
  container_size_type: yup.string().required("Required"),
  cargo_type: yup.string().required("Required"),
  bn_image: yup
    .mixed()
    .required("Required")
    .test("fileSize", "File size should be less than 1MB", function (value) {
      if (value instanceof File) {
        return (
          !value || (value instanceof File && value.size <= 1 * 1024 * 1024)
        ); // 1MB
      }
      return true; // Skip size validation if condition is not met
    })
    .test("fileType", "Unsupported file format", function (value) {
      if (value instanceof File) {
        return (
          !value ||
          (value instanceof File &&
            ["image/jpeg", "image/png", "application/pdf"].includes(value.type))
        ); // 1MB
      }
      return true; // Skip size validation if condition is not met
    }),
});

export const ImportFormSchema = yup.object().shape({
  yard: yup.number().required("Required"),
  drop_shedule_date: yup.date().required("Required"),
  shipping_line: yup.number().required("Required"),
  containers: yup.array().of(
    yup.object({
      container_size_type: yup.string().required("Required"),
      container_number: yup
        .string()
        .required("Required")
        .matches(
          /^[A-Z]{4}[0-9]{7}$/,
          "Invalid container number format. Expected format is AAAA9999999"
        )
        .test(
          "length",
          "Container number should be 11 characters",
          (val) => val && val.length === 11
        )
        .test("check-digit", "Invalid Container number", function (value) {
          if (!value && value.length <= 11) return false;

          const expectedCheckDigit = calculateCheckDigit(value);
          const actualCheckDigit = parseInt(value[value.length - 1]);

          return expectedCheckDigit === actualCheckDigit;
        })
        .test(
          "unique-pairs",
          "Container Number must be unique",
          function (item) {
            const containersArray = this.from;
            const values = this.parent;
            return (
              containersArray?.[1].value?.containers?.filter(
                (ele) => ele?.container_number === values?.container_number
              ).length <= 1
            );
          }
        ),
    })
  ),
  do_number: yup
    .string()
    .required("Required")
    .test("length", "Do number should not exceed 32 characters", (val) => {
      return val && val.length <= 32;
    })
    .matches(alphanumericRegex, "Do Number cannot have special character"),
  do_image: yup
    .mixed()
    .required("Required")
    .test("fileSize", "File size should be less than 1MB", function (value) {
      if (value instanceof File) {
        return (
          !value || (value instanceof File && value.size <= 1 * 1024 * 1024)
        );
      }
      return true;
    })
    .test("fileType", "Unsupported file format", function (value) {
      if (value instanceof File) {
        return (
          !value ||
          (value instanceof File &&
            ["image/jpeg", "image/png", "application/pdf"].includes(value.type))
        );
      }
      return true;
    }),
});

export const NewTranporterFormSchema = yup.object().shape({
  first_name: yup.string().required("Transporter Name is Required"),
  last_name: yup.string().required("Transporter Name is Required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is Required"),
  pan_number: yup
    .string()
    .transform((value) => value.toUpperCase())
    .matches(panCardRegex, "Invalid Pan Number")
    .length(10, "PAN number must be 10 characters long")
    .required("Pan Number is Required"),
  phone_number: yup
    .string()
    .min(13, "Phone number must be at least 10 characters long")
    .max(19, "Phone number should not exceed 15 digits")
    .required("Phone Number is Required"),
  gstin_number: yup
    .string()
    .transform((value) => value.toUpperCase())
    .matches(gstinRegex, "Invalid GST Number")
    .required("GSTIN Number is Required")
    .test(
      "is-pan-matching",
      "GSTIN must contain the PAN number",
      function (value) {
        const { pan_number } = this.parent;
        if (value && pan_number) {
          const gstinPanPart = value.slice(2, 12); // Extract the PAN part from GSTIN
          return gstinPanPart === pan_number;
        }
        return true;
      }
    ),
  pan_doc: yup
    .mixed()
    .required("Pan Document is Required")
    .test("fileSize", "File size should be less than 1MB", function (value) {
      if (value instanceof File) {
        return (
          !value || (value instanceof File && value.size <= 1 * 1024 * 1024)
        ); // 1MB
      }
      return true; // Skip size validation if condition is not met
    })
    .test("fileType", "Unsupported file format", function (value) {
      if (value instanceof File) {
        return (
          !value ||
          (value instanceof File &&
            ["image/jpeg", "image/png", "application/pdf"].includes(value.type))
        ); // 1MB
      }
      return true; // Skip size validation if condition is not met
    }),
  gstin_doc: yup
    .mixed()
    .required("GSTIN Document is Required")
    .test("fileSize", "File size should be less than 1MB", function (value) {
      if (value instanceof File) {
        return (
          !value || (value instanceof File && value.size <= 1 * 1024 * 1024)
        ); // 1MB
      }
      return true; // Skip size validation if condition is not met
    })
    .test("fileType", "Unsupported file format", function (value) {
      if (value instanceof File) {
        return (
          !value ||
          (value instanceof File &&
            ["image/jpeg", "image/png", "application/pdf"].includes(value.type))
        ); // 1MB
      }
      return true; // Skip size validation if condition is not met
    }),
});

export const fetchFormSchema = yup.object().shape({
  first_name: yup.string().required("Transporter Name is Required"),
  last_name: yup.string().required("Transporter Name is Required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is Required"),
  gstin_number: yup
  .string()
  .transform((value) => value.toUpperCase())
  .matches(gstinRegex, "Invalid GST Number")
  .required("GSTIN Number is Required")
  .test(
    "is-pan-matching",
    "GSTIN must contain the PAN number",
    function (value) {
      const { pan_number } = this.parent;
      if (value && pan_number) {
        const gstinPanPart = value.slice(2, 12); // Extract the PAN part from GSTIN
        return gstinPanPart === pan_number;
      }
      return true;
    }
  ),  
});