import { Box, Grid, Typography } from '@mui/material'
import HeaderSection from '../../Components/HeaderSection'
import { formContainer, formTitle } from '../../Styles/Import/formSection'
import { useEffect, useState } from 'react';
import { useDownloadBillingInvoiceFileMutation, useLazyFetchInvoiceDetailQuery } from '../../Services/AccountApi';
import { enqueueSnackbar } from 'notistack';
import InvoiceTable from './InvoiceTable';
import { ReactComponent as RefreshSvgIcon } from '../../Assets/icons/refresh-icon.svg';
import { DownloadBoldIconButtonStyle, LightTooltip, RefreshIconButtonStyle } from '../../Styles/Common/style';
import FilterSelectField from '../../Components/FilterSelectField';
import { Helmet } from 'react-helmet-async';
import GlobalLoader from '../../Components/GlobalLoader';
import FilterCustomDateTimePicker from '../../Components/FilterCustomDateTimePicker';
import dayjs from 'dayjs';
import DownloadIcon from '../../Assets/icons/downloadBold.svg';
import { convertDateintoDDMMYYYY } from '../../Utils/common';

const ServiceOptions = [
    { id: "import", name: "Import" },
    { id: "export", name: "Export" },
]

const SortOptions = [
    { id: "asc", name: "Ascending" },
    { id: "desc", name: "Descending" },
]
const arrayToDict = (arr) => {
    return arr?.reduce((acc, item) => {
        acc.push({ id: item, name: item })
        return acc;
    }, []);
};
const Invoice = () => {
    const [filterParams, setFilterParams] = useState({ selectedNumber: { import: "", export: "" }, sort: "", operation: 'import', page: 1 })
    const [invoiceData, setInvoiceData] = useState()
    const [fetchInvoiceDetail, { isLoading: fetchInvoiceDetailApiLoading }] = useLazyFetchInvoiceDetailQuery();
    const [downloadFileApi, { isLoading }] = useDownloadBillingInvoiceFileMutation()
    useEffect(() => {
        const getScheduleOptions = async () => {
            let params = {
                operation: filterParams?.operation,
                sort: filterParams?.sort,
                page: filterParams?.page,
                date: filterParams?.date,
            }
            if (filterParams?.operation === "import") {
                params['do_number'] = filterParams?.selectedNumber?.import
            } else {
                params['bn_number'] = filterParams?.selectedNumber?.export
            }
            const { data, error } = await fetchInvoiceDetail(params);
            if (data) {
                setInvoiceData(data)
            }
            if (error) {
                enqueueSnackbar(error?.data?.message || "Something went wrong !", {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                });
                setInvoiceData([])
                console.log(error)
            }
        }
        getScheduleOptions()
    }, [filterParams])


    const handleDateChange = async (value) => {
        setFilterParams(prev => ({
            ...prev,
            date: dayjs(value).format('DD-MM-YYYY')
        }))
    }

    const handleInvoiceType = async (event) => {
        setFilterParams(prev => ({
            ...prev,
            operation: event.target.value
        }))
    }

    const handleSorting = async (event) => {
        setFilterParams(prev => ({
            ...prev,
            sort: event.target.value
        }))
    }

    const handlePageChange = async (page) => {
        setFilterParams(prev => ({
            ...prev,
            page: page
        }))
    }

    const handleSelectNumber = async (event) => {
        if (filterParams?.operation === 'import') {
            setFilterParams(prev => ({
                ...prev,
                selectedNumber: {
                    ...prev.selectedNumber,
                    import: event?.target?.value, // Replace "newValue" with the value you want to set
                },
            }))
        } else {
            setFilterParams(prev => ({
                ...prev,
                selectedNumber: {
                    ...prev.selectedNumber,
                    export: event?.target?.value, // Replace "newValue" with the value you want to set
                },
            }))
        }
    }

    const handleRefresh = async () => {
        setFilterParams({ selectedNumber: { import: "", export: "" }, sort: "", operation: 'import', page: 1 })
    }

    const handleExport = async () =>{
        let body = {
            operation: filterParams?.operation,
            sort: filterParams?.sort,
            page: filterParams?.page,
            date: filterParams?.date,
        }
        if (filterParams?.operation === "import") {
            body['do_number'] = filterParams?.selectedNumber?.import
        } else {
            body['bn_number'] = filterParams?.selectedNumber?.export
        }
        const {data, error} = await downloadFileApi(body);
        if (data) {

        }
        if (error) {
            enqueueSnackbar(error?.data?.message || "Something went wrong !", {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
        }
    }

    return (
        <Box sx={{ background: '#F5F5F5' }}>
            <Helmet>
                <title>Billing/Invoices | Gati se Pragati</title>
            </Helmet>
            <HeaderSection title="BILLING/INVOICES" />
            <Box sx={formContainer}>
                <Box display={'flex'} margin={"2rem 0px"} gap={"1rem"}>
                    <Typography flex={'1'} sx={formTitle}>  Billing / Invoices Details </Typography>
                    <LightTooltip title="Download the Billing/Invoices in the Excel Format." arrow>
                        <Box sx={{ ...RefreshIconButtonStyle, background: "#933118 0% 0% no-repeat padding-box", cursor: "pointer" }} onClick={handleExport}>
                            <img src={DownloadIcon} alt="" width={"100%"} />
                        </Box>
                    </LightTooltip>
                    <Box sx={{ ...RefreshIconButtonStyle, cursor: "pointer" }} onClick={()=>handleRefresh()}>
                        <RefreshSvgIcon width={"100%"} height={"100%"} rotate={"90deg"} />
                    </Box>
                </Box>
                <Grid container spacing={2} marginBottom={"2rem"} justifyContent="center">
                    <Grid item xs={12} md={3} padding="0px" width="fit-content">
                        <FilterSelectField
                            name={'service'}
                            showSelect={true}
                            placeholder={'select'}
                            value={filterParams?.sort}
                            label={'Sort by'}
                            onChange={handleSorting}
                            options={SortOptions}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} padding="0px" width="fit-content" >
                        <FilterCustomDateTimePicker
                            name={'schedule_date'}
                            placeholder={'select'}
                            value={convertDateintoDDMMYYYY(filterParams?.date)}
                            label={'Filter By Date'}
                            allDates={true}
                            onChange={handleDateChange}
                        // onChange={filterParams?.date}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} padding="0px" width="fit-content" >
                        <FilterSelectField
                            name={'service'}
                            showSelect={false}
                            placeholder={'select'}
                            label={'Select Service'}
                            value={filterParams?.operation}
                            onChange={handleInvoiceType}
                            options={ServiceOptions}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} padding="0px" width="fit-content" >
                        <FilterSelectField
                            showSelect={true}
                            name={'do_bn_number'}
                            placeholder={'select'}
                            value={filterParams?.operation === 'import' ? filterParams?.selectedNumber?.import : filterParams?.selectedNumber?.export}
                            label={filterParams?.operation === 'import' ? 'DO Number' : 'Booking Number'}
                            options={filterParams?.operation === 'import' ? arrayToDict(invoiceData?.do_numbers) : arrayToDict(invoiceData?.bn_numbers) || []}
                            onChange={handleSelectNumber}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ background: '#fff' }}>
                    <InvoiceTable
                        selectedNumber={filterParams.selectedNumber}
                        tableData={invoiceData}
                        selectedType={filterParams?.operation}
                        handlePageChange={handlePageChange}
                    />
                </Box>
            </Box>
            <GlobalLoader open={Boolean(fetchInvoiceDetailApiLoading)} />
        </Box>
    )
}

export default Invoice